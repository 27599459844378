<div
  [ngClass]="{
    red: notificationType() === 'error',
    blue: notificationType() === 'info',
    green: notificationType() === 'success',
    dark: notificationType() === 'dark',
  }"
  class="flex gap-3 items-center rounded p-1.5 md:p-2 h-auto w-full"
  [attr.role]="this.notificationType() === 'error' ? 'alert' : 'status'"
>
  <i class="fa-regular fa-circle-exclamation text-2xl p-0.5" [class.hidden]="hideIcon()"></i>
  <div><ng-content></ng-content></div>
</div>
