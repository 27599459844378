import { CommonModule } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-password-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-password-field.component.html',
  styleUrl: './form-password-field.component.scss',
})
export class FormPasswordFieldComponent {
  control = input.required<FormControl>();
  name = input.required<string>();
  iconButton = input<string>();
  isDisabled = input<boolean>(false);
  placeholder = input<string>('');
  height = input<string>();

  onIconButtonClicked = output<void>();

  protected isPasswordVisible = signal<boolean>(false);

  protected togglePasswordVisibility() {
    this.isPasswordVisible.update((value) => !value);
  }
}
