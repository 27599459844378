<div class="flex flex-row items-center gap-4">
  <input
    #radioOption
    [id]="controlId()"
    [name]="controlName()"
    type="radio"
    [value]="radioValue()"
    [(ngModel)]="value"
    (input)="onChange($event)"
    (blur)="blur()"
    class="checkbox"
    [disabled]="isDisabled()"
  />
  <label [for]="controlId()"><ng-content></ng-content></label>
</div>
