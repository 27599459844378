import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ageValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(value);
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    return birthDate > minDate ? { age: true } : null;
  };
}
