<header
  class="bg-primary h-masthead-mobile md:min-h-masthead-desktop md:h-auto px-4 md:px-10 flex flex-row items-center md:gap-16"
  role="banner"
>
  <div class="flex-1 md:flex-none">
    <a [routerLink]="['/']" aria-label="Go to Bongo's Bingo homepage">
      <img src="assets/images/logo.svg" class="h-11" alt="" />
    </a>
  </div>

  <nav class="hidden md:flex flex-row gap-12 items-center text-lg [&_a]:font-bold text-white flex-1">
    <div class="flex flex-row flex-wrap items-center flex-1 gap-x-12">
      <a [routerLink]="['/', 'events']" aria-label="Search for an event">EVENTS</a>
      <!-- TODO: Removed until implemented -->
      <!-- <a [routerLink]="['/']" aria-label="Who are the Hosts and Dancers?">HOSTS & DANCERS</a>
      <a [routerLink]="['/']" aria-label="What to expect at Bongo's Bingo">WHAT IS BONGO'S?</a>
      <a [routerLink]="['/']" aria-label="Frequently asked questions">FAQ'S</a>
      <a [routerLink]="['/']" aria-label="Get in touch with Bongo's Bingo">CONTACT</a> -->
    </div>

    <div class="flex flex-row items-center gap-4">
      @if (auth.authenticated()) {
        <a [routerLink]="['/profile']" class="flex gap-2 outline-button secondary" aria-label="Go to your profile page"
          ><i class="fa-solid fa-circle-user"></i>Profile</a
        >
        <button class="outline-button" (click)="logout()" aria-label="Logout of Bongo's Bingo">Logout</button>
      } @else {
        <a
          [routerLink]="['/', 'login']"
          [queryParams]="{ redirect: redirectUrl() }"
          class="outline-button active:bg-buttons-primary-active"
          aria-label="Login to Bongo's Bingo"
          >Login</a
        >
        <a
          [routerLink]="['/register']"
          class="filled-button secondary"
          aria-label="Register a new Bongo's Bingo account"
          >Register</a
        >
      }
    </div>
  </nav>

  <div class="md:hidden">
    <button class="icon-button focus:outline-none" (click)="openDrawer()" aria-label="Open the navigation menu">
      <img src="assets/images/menu.svg" alt="" />
    </button>
  </div>
</header>

<app-busy-overlay [busy]="!!overlayMessage()">{{ overlayMessage() }}</app-busy-overlay>
