import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-date-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-date-field.component.html',
  styleUrl: './form-date-field.component.scss',
})
export class FormDateFieldComponent {
  control = input.required<FormControl>();
  name = input.required<string>();
  type = input<string>('text');
}
