import { inject, Injectable } from '@angular/core';
import { ApiService } from '@features/api';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  protected api = inject(ApiService);
  constructor() {}

  subscribeToNewsletter(email: string) {
    return this.api.post('/newsletters', { email });
  }
}
