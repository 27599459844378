import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormTextFieldComponent, FormValidationErrorsComponent } from '@components/form-fields';
import { tap, catchError, EMPTY, finalize } from 'rxjs';
import { SnackBar } from 'src/components/snack-bar';
import { SpinnerComponent } from 'src/components/spinner';
import { StaticNotificationComponent } from 'src/components/static-notification';
import { FooterService } from '../../services/footer.service';
import { emailValidator } from '@features/common/validators/email.validator';

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormTextFieldComponent,
    FormValidationErrorsComponent,
    SpinnerComponent,
    StaticNotificationComponent,
  ],

  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterComponent {
  #footerService = inject(FooterService);
  #snackBar = inject(SnackBar);
  #destroyRef = inject(DestroyRef);
  fb = inject(FormBuilder);
  form = this.fb.group({
    newsletterEmail: ['', [Validators.required, Validators.email, emailValidator()]],
  });
  protected isBusy = signal<boolean>(false);
  protected isSubscribed = signal<boolean>(false);
  protected isSubmitted = signal<boolean>(false);

  get newsletterEmailControl(): FormControl {
    return this.form.get('newsletterEmail') as FormControl;
  }

  protected subscribeToNewsletter() {
    if (this.form.valid) {
      this.isBusy.set(true);
      this.isSubmitted.set(true);
      const raw = this.form.getRawValue();
      this.#footerService
        .subscribeToNewsletter(raw.newsletterEmail!)
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          tap(() => {
            this.isSubscribed.set(true);
            this.form.markAsPristine();
          }),
          catchError(({ errors }) => {
            if (errors) {
              const errorMessages = Object.values(errors).flat().join(' ');
              this.#snackBar.error(`An error occurred subscribing to the newsletters: ${errorMessages}`);
            }
            return EMPTY;
          }),
          finalize(() => this.isBusy.set(false)),
        )
        .subscribe();
    }
  }

  visitPage(appName: AppName) {
    const appLink = this.getAppLink(appName);
    window.open(appLink, '_blank');
  }

  getAppLink(appName: AppName): string {
    const currentUrl = window.location.href;

    switch (appName) {
      case 'facebook':
        return 'https://www.facebook.com/BongosBingo/';
      case 'instagram':
        return `https://www.instagram.com/bongosbingo/`;
      case 'email':
        return `mailto:?subject=Check this out&body=Check out this link: ${currentUrl}`;
      case 'youtube':
        return `https://www.youtube.com/channel/UCHlz4LI1eZXuskb7OPt-2wQ`;
      case 'tiktok':
        return `https://www.tiktok.com/@bongosbingoofficial`;
      case 'x':
        return 'https://x.com/BongosBingo';
    }
  }
}

export type AppName = 'facebook' | 'instagram' | 'email' | 'youtube' | 'tiktok' | 'x';
