import { AbstractControl, ValidatorFn } from '@angular/forms';

export function doNotMatch(controlAName: string, controlBName: string, caseSensitive = false): ValidatorFn {
  return (controlGroup: AbstractControl) => {
    const controlAValue = controlGroup.get(controlAName)?.value ?? '';
    const controlBValue = controlGroup.get(controlBName)?.value ?? '';

    const stringsMatch = controlAValue.localeCompare(controlBValue, undefined, {
      sensitivity: caseSensitive ? 'case' : 'accent',
    });
    return stringsMatch !== 0 ? { doNotMatch: true } : null;
  };
}
