import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-switch-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-switch-field.component.html',
  styleUrl: './form-switch-field.component.scss',
})
export class FormSwitchFieldComponent {
  control = input.required<FormControl>();
  name = input.required<string>();
  ariaLabel = input.required<string>();
}
