import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null;
    }

    const phoneRegex = /^(?:\+44|0)7((\d{3})\s(\d{6})|(\d{9}))$/;

    return phoneRegex.test(value) ? null : { phoneNumber: true };
  };
}
