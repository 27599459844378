import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, forwardRef, input, viewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorBase } from '../control-value-accessor-base/control-value-accessor.base';

@Component({
  selector: 'app-form-radio-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './form-radio-field.component.html',
  styleUrl: './form-radio-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioFieldComponent),
      multi: true,
    },
  ],
})
export class FormRadioFieldComponent<T> extends ControlValueAccessorBase<T> implements AfterViewInit {
  controlId = input.required<string>();
  controlName = input.required<string>();
  radioValue = input.required<string>();

  radioOption = viewChild.required<ElementRef<HTMLInputElement>>('radioOption');

  protected onChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.change(input.value as T);
  }

  ngAfterViewInit() {
    this.radioOption().nativeElement.name = this.controlName();
  }
}
