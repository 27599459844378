import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordStrengthValidator(label: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbol = /[!@#£$%^&*(),.?":{}|<>]/.test(value);

    return {
      ...(!hasUpperCase && { passwordStrengthUpperCase: `${label} must have at least one uppercase character` }),
      ...(!hasLowerCase && { passwordStrengthLowerCase: `${label} must have at least one lowercase character` }),
      ...(!hasNumber && { passwordStrengthHasNumber: `${label} must have at least one number` }),
      ...(!hasSymbol && { passwordStrengthHasSymbol: `${label} must have at least one symbol` }),
    };
  };
}
