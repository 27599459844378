import { signal } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export class ControlValueAccessorBase<T> implements ControlValueAccessor {
  #value!: T;
  isDisabled = signal<boolean>(false);

  #onChange = (_value: T) => {};
  #onTouched = () => {};
  #onValidatorChange = () => {};

  get value(): T {
    return this.#value;
  }
  set value(value: T) {
    if (value !== this.#value) {
      this.#value = value;
      this.#onChange(value);
      this.#onValidatorChange();
    }
  }

  writeValue(value: T) {
    this.#value = value;
  }

  registerOnChange(fn: (_value: T) => void) {
    this.#onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.#onTouched = fn;
  }

  setDisabledState?(disabled: boolean) {
    this.isDisabled.set(disabled);
    this.onDisabledStateChange(disabled);
  }

  registerOnValidatorChange?(fn: () => void) {
    this.#onValidatorChange = fn;
  }

  protected change(value: T) {
    this.#onChange(value);
    this.#onTouched();
  }

  protected blur() {
    this.#onTouched();
  }

  protected touched() {
    this.#onTouched();
  }

  protected onDisabledStateChange(isDisabled: boolean) {
    this.isDisabled.set(isDisabled);
  }
}
