@if (control()) {
  <div class="relative inline-block w-16 h-8 align-middle select-none transition duration-200 ease-in">
    <input type="checkbox" [id]="name()" [name]="name()" [formControl]="control()" class="toggle-checkbox hidden" />

    <label
      [for]="name()"
      class="toggle-label block overflow-hidden h-8 rounded-full cursor-pointer bg-white bg-opacity-30 focus:outline-none focus:border focus:border-primary"
      tabindex="0"
      [attr.aria-checked]="control().value ? 'true' : 'false'"
      role="switch"
      [attr.aria-label]="ariaLabel()"
    >
      <span
        class="toggle-span absolute block rounded-full transition-transform duration-200 ease-in-out"
        [ngClass]="{ 'translate-x-full bg-primary': control().value, 'bg-white bg-opacity-20': !control().value }"
      ></span>
    </label>
  </div>
}
