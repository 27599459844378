import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewsletterComponent } from './components/newsletter/newsletter.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterModule, NewsletterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {}
