import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

export type BackgroundColor = 'default' | 'black';

@Component({
  selector: 'app-form-text-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-text-field.component.html',
  styleUrl: './form-text-field.component.scss',
})
export class FormTextFieldComponent {
  control = input.required<FormControl>();
  type = input<string>('text');
  name = input.required<string>();
  placeholder = input<string>('');
  iconButton = input<string>();
  background = input<BackgroundColor>('default');
  height = input<string>();
  onIconButtonClicked = output<void>();
  isDisabled = input<boolean>(false);
  isCapitalized = input<boolean>(false);

  protected iconButtonClick() {
    this.onIconButtonClicked.emit();
  }

  backgroundClasses = computed(() => {
    return this.background() === 'default'
      ? 'bg-form-field-color bg-opacity-10 border border-white focus:border-white hover:border-white active:border-white placeholder-white'
      : 'bg-black';
  });
}
