import { Directive, ElementRef, inject, input, OnDestroy, OnInit, output } from '@angular/core';

@Directive({
  selector: '[appScrolledIntoView]',
  standalone: true,
})
export class ScrolledIntoViewDirective implements OnInit, OnDestroy {
  triggerElement = inject(ElementRef);

  multiTrigger = input<boolean>(false);

  isVisible = output<void>();

  #observer!: IntersectionObserver;

  ngOnInit() {
    this.#observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible.emit();
          if (!this.multiTrigger()) {
            this.#observer.unobserve(entry.target);
          }
        }
      });
    });

    this.#observer.observe(this.triggerElement.nativeElement);
  }

  ngOnDestroy() {
    this.#observer.disconnect();
  }
}
